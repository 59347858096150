(function ($) {

  $(document).ready( function() {
    $('.testimonials ul').slick({
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  });

})(jQuery);
